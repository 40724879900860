import React from 'react';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import BlogPostCard from '../components/BlogPostCard';
import Pagination from '../components/Pagination';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const BlogPage = ({ data, pageContext }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { title } = data.site.siteMetadata;

  const {
    currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  return (
    <>
      <Helmet title={`${title} | Stories: page ${currentPage}`} />
      <Layout>
        <Img fluid={data.file.childImageSharp.fluid} />
        <section className="section">
          <div className="container">
            <div className="section">
              <div className="columns is-multiline">
                {posts &&
                  posts.map(({ node: post }) => {
                    const className = `blog-list-item tile is-child ${
                      post.frontmatter.featuredpost ? 'is-featured' : ''
                    }`;

                    const featuredImage = post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.title}`,
                          }}
                        />
                      </div>
                    ) : null;

                    return (
                      <div className="is-parent column is-6" key={post.id}>
                        <BlogPostCard
                          className={className}
                          featuredImage={featuredImage}
                          link={post.fields.slug}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          excerpt={post.excerpt}
                        />
                      </div>
                    );
                  })}
              </div>
              <Pagination
                prevPagePath={prevPagePath}
                nextPagePath={nextPagePath}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export const blogPageQuery = graphql`
  query BlogPageQuery($postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    file(name: { regex: "/index-page-blog/" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            description
            date(formatString: "DD MMMM YYYY", locale: "bg")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 640, maxHeight: 350, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(format: PLAIN, pruneLength: 200)
        }
      }
    }
  }
`;

export default BlogPage;
